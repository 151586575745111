<!-- eslint-disable tailwindcss/no-custom-classname -->
<template>
  <div
    class="search-suggestions custom-scrollbar flex w-full flex-col overflow-y-auto rounded-3xl bg-white lg:auto-rows-auto"
    :class="[{ 'lg:grid': props.enableSearchPhraseSuggestions || props.enableSearchPhraseCategories }]"
  >
    <div class="order-2 max-w-[350px] px-4 xxs:px-8 md:min-w-[350px] lg:pl-0 lg:pr-6">
      <div
        v-if="!props.isLoading"
        class="flex h-full flex-col"
      >
        <div
          v-if="props.enableSearchPhraseSuggestions && props.searchPhraseSuggestions.length > 0"
          class="order-2 border-t border-t-blue-450 pt-4 lg:order-none lg:border-t-0 lg:pt-0"
        >
          <p
            v-if="props.labels?.searchPhraseSuggestionsTitle"
            class="mb-4 hidden pb-4 text-blue md:block lg:mb-5 lg:pb-0"
          >
            {{ props.labels.searchPhraseSuggestionsTitle }}
          </p>
          <ul
            class="text-base"
          >
            <li
              v-for="(item, index) in props.searchPhraseSuggestions"
              :key="typeof item.link === 'string' ? item.link : item.link?.path ?? `${index}-suggestions` "
            >
              <NuxtLink
                :to="item.link"
                :class="styles.link"
                data-test="app-search-suggested-phrase-item"
              >
                <UiIcon
                  name="search"
                  :width="16"
                  :height="16"
                  :class="styles.icon"
                />
                <HtmlParser
                  :html="markSearchPhrase(item.label)"
                  :class="{ 'font-bold': props.mode === 'results' }"
                  tag="span"
                />
              </NuxtLink>
            </li>
          </ul>
        </div>

        <div
          v-if="props.enableSearchPhraseCategories && props.searchPhraseCategories.length > 0"
          class="order-1 lg:order-none"
          :class="[
            {
              'border-t-0 pt-5 md:mt-5 lg:border-t lg:border-t-blue-450':
                props.searchPhraseSuggestions?.length,
            },
          ]"
        >
          <p
            v-if="props.labels?.searchPhraseCategoriesTitle"
            class="mb-4 hidden border-b border-blue-450 pb-4 md:block lg:mb-5 lg:border-b-0 lg:pb-0"
          >
            {{ props.labels.searchPhraseCategoriesTitle }}
          </p>
          <ul
            class="pb-4 text-base lg:pb-0"
          >
            <li
              v-for="item in props.searchPhraseCategories"
              :key="item.link"
            >
              <NuxtLink
                :to="item.link"
                :class="styles.link"
                data-test="app-search-category-item"
              >
                <UiIcon
                  name="tabler-icon-category"
                  :width="24"
                  :height="24"
                  :class="styles.icon"
                />
                {{ item.label }}
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
      <div
        v-if="props.isLoading && (props.enableSearchPhraseSuggestions || props.enableSearchPhraseCategories)"
        class="flex h-full flex-col"
      >
        <Skeletor
          v-for="item in 8"
          :key="item"
          class="mb-3"
          :pill="true"
          :height="12"
        />
      </div>
    </div>
    <div
      class="search-suggestions__products order-1 mt-3 max-w-[650px] px-4 xxs:px-8 lg:mt-0 lg:pl-6 lg:pr-12"
      :class="props.productsContainerClass"
    >
      <template v-if="!props.isLoading">
        <p
          v-if="props.labels?.leftColumnTitle"
          class="mb-4 border-b border-blue-450 pb-3 text-blue lg:mb-3"
          :class="{
            'lg:hidden': props.mode === 'results',
          }"
        >
          {{ props.labels.leftColumnTitle }}
        </p>
        <slot name="products" />

        <slot
          name="moreProducts"
          v-bind="{
            label: props.labels.moreProducts,
            link: props?.moreProducts,
          }"
        >
          <UiButton
            v-if="props?.moreProducts"
            :to="props.moreProducts"
            variant="link"
            icon-right="arrow"
            :icon-dimensions="{ width: 6, height: 10 }"
            class="mt-4 pr-5 normal-case"
          >
            {{ props.labels.moreProducts }}
          </UiButton>
        </slot>
      </template>
      <div
        v-else
        class="mt-5 lg:mt-0"
      >
        <div
          v-for="item in 6"
          :key="item"
          class="mb-3 flex items-center"
        >
          <Skeletor
            as="div"
            :height="80"
            :width="80"
          />
          <div class="ml-4 grow">
            <Skeletor
              class="mb-2"
              :pill="true"
              :height="15"
            />
            <Skeletor
              class="mb-2"
              :pill="true"
              :height="15"
            />
            <Skeletor
              class="mb-2"
              :pill="true"
              :height="15"
            />
          </div>
          <Skeletor
            class="ml-8"
            as="div"
            :pill="true"
            :height="40"
            :width="60"
          />
        </div>
      </div>
    </div>
    <div class="order-3 col-start-2 mt-12 flex items-end px-4 xxs:px-8 lg:mt-0 lg:pl-0 lg:pr-6">
      <slot
        v-if="!props.isLoading"
        name="promotionBanner"
      />
      <Skeletor
        v-if="props.isLoading && $slots.promotionBanner"
        class="grow"
        as="div"
        :height="120"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Skeletor } from 'vue-skeletor'
import { computed } from 'vue'
import UiIcon from '../UiIcon/UiIcon.vue'
import UiButton from '../UiButton/UiButton.vue'
import type { UiSearchSuggestionsProps } from './UiSearchSuggestions.types'

const props = withDefaults(defineProps<UiSearchSuggestionsProps>(), {
  mode: 'init',
  moreProducts: undefined,
  isLoading: false,
  searchPhrase: '',
  enableSearchPhraseSuggestions: true,
  enableSearchPhraseCategories: true,
  searchPhraseSuggestions: () => ([]),
  searchPhraseCategories: () => ([]),
  productsContainerClass: () => ([]),
  labels: {
    leftColumnTitle: '',
    searchPhraseSuggestionsTitle: '',
    searchPhraseCategoriesTitle: '',
    moreProducts: '',
  },
})

const styles = computed(() => ({
  link: 'flex cursor-pointer items-center py-1 text-primary hover:underline break-words',
  icon: 'class="ml-1 mr-2 text-grey shrink-0',
}))

function markSearchPhrase(suggestion: string): string {
  if (props.searchPhrase && suggestion.includes(props.searchPhrase)) {
    const searchPattern = new RegExp(props.searchPhrase, 'gi')
    return suggestion.replace(searchPattern, (match) => {
      return `<span class="font-normal">${match}</span>`
    })
  }
  return suggestion
}
</script>

<style lang="postcss">
@media (min-width: 1024px) {
  .search-suggestions__products {
    grid-row: 1 / 3;
  }
}
</style>
